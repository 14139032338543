.footer {
  // position: absolute;
  width: 100%;
  min-height: 65px;
  line-height: 60px;
  background-color: #193a49;
  margin-top: 1em;
}
.small .footer {
  line-height: 15px;
  text-align: left;
  min-height: auto;
  font-size: 11px;
  padding: 0px 10px;
  padding-top: 5px;
}
.footer > .container:last-child {
  background-color: #499b7b;
  padding-right: 15px;
  padding-left: 15px;
  color: #fff;
  bottom: 0;
  position: absolute;
}

.footerRow ul {
  display: flex;
  color: #fff;
  flex-wrap: wrap;
}
.small .footerRow ul {
  padding: 0;
}
.footerRow ul li {
  flex-grow: 1;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}
