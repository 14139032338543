#ut-player {
  width: 100%;
  height: 35.5em;
}

// #ut-player.small,
// small {
//   width: 100%;
//   height: auto;
// }
