@import "../../styles/colors.scss";
.banners {
 gap:5px;
 justify-content: space-between;
}

.banners div > img {
  width: 180px;
  border-radius: 5px;
  /* width: 100%; */
  // border: 4px solid #499b7b;
 }

 .banners a > img {
  width: 180px;
  border-radius: 5px;
  /* width: 100%; */
 }
