header {
  height: 107px;
}
header.small {
  height: 55px;
}

header > div {
  background-color: #193d4d;
}

div#mainSearchcontainer {
  align-items: center;
}

.img-fluid {
  width: 5rem;
}
.navbar {
  padding: 0.5rem 3rem;
}
.navbar-toggler {
  background-color: #499b7b;
  border: 1px solid #499b7b;
  border-radius: 0.25rem;
}
.navbar-toggler-icon {
  color: #fff;
}
.layout {
  min-height: 100vh;
}

body > .container {
  padding: 140px 15px 0;
}

.listItem {
  cursor: pointer;
  // border: 1px solid #b5abab21;
  background: #dddddd6b;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 260px;
  text-align: center;
  min-height: 200px;
}

.listItem:hover {
  border: 1px solid #499b7b;
}

.listItem.active {
  // background: #dddddd6b;
  border: 1px solid #499b7b;
}

.playList.simple {
  // max-height: 100vh;.footer
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: inherit;
}
.playList.simple.small {
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  padding: auto;
}

.playList {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  /* grid-auto-flow: row; */
  grid-auto-flow: column;
  gap: 10px;
  // overflow: auto;
  padding-bottom: 10px;

  i{
    width: 233px;
  }
  
}

.sidebar{
  position: fixed;
  max-width: 350px;
  background-color: #fff;
  z-index: 99;
  width: 100%;
  height: 100vh;
  top: 50px;
}
// .playList::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .playList {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }

.small {
  .info * {
    font-size: 0.94em;
  }
}

.small .navbar {
  padding: 0 0.5rem;
}

.small .header-item {
  justify-content: space-between;
}
.small .navbar-brand {
  padding: 0;
}
.small .navbar-brand > img {
  height: 50px;
  margin-top: 3px;
}

.toogle div {
  width: 30px;
  height: 3px;
  background-color: #499b7b;
  margin: 6px 0;
}

.small {
  .tabs.navbar-nav {
    margin: 5px 0;
    flex-wrap: wrap;
  }
  .nav-item {
    width: 30%;
  }
  .nav-link {
    border-radius: 5px;
    color: white;
    background-color: #193d4d;
    margin: 5px 5px 5px 0;
    font-size: 10px;
    text-align: center;
  }
}

.tab-content {
  padding: 11px;
  border: 1px solid #dee2e6;
  border-radius: 0 0 5px 5px;
  border-top: none;
}
