.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  // background-color: $dark-green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.playlist{
  margin-top:230px ;
}

.offcanvas-body{
  overflow-y: auto;
    height: 80%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div[role="tooltip"] {
  z-index: 1030;
}

.main-container {
  padding-top: ".4em";
}

.btn {
  background-color: #193a49;
  border-radius: 5px;
  cursor: pointer;
  // text-transform: capitalize;
  flex-grow: 1;
  align-items: center;
  color: #fff;
  font-size: 13px;
  text-align: center;
  display: flex;
  flex-grow: 1;
}
.btn:hover {
  color: #fff;
}

.btn-check:focus + .btn,
.btn:focus {
  background-color: #499b7b;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #499b7b;
  border-color: #499b7b;
}
.page-item {
  margin-left: 2px;
}

.page-link:hover {
  z-index: 2;
  background-color: #193a49;
  border-color: #499b7b;
}

.contact {
  width: 100%;
  height: 78vh;
  overflow: hidden;
}
.searchbar-container input {
  font-size: inherit;
}

.reset-filters-btn > a {
  display: block;
  width: 100%;
  text-align: left;
}

.series-wraper {
  margin: 0 auto;
}

.paginate-small {
  position: fixed;
  z-index: 3;
  background: #fff;
  top: 52px;
  padding: 8px;
}

// .series-list-small {
//   margin-top: 210px;
// }
// .series-list-small-pager {
//   margin-top: 260px;
// }

@media only screen and (min-device-width: 220px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  #ut-player {
    width: 100%;
    height: auto !important;
    min-height: 214px;
  }
  .btn {
    font-size: 11px;
    text-align: left;
  }
  .page-link.btn {
    padding: 9px;
  }
}
