@import "./colors";

html {
  font-size: 16px;
}

h1,
.title {
  font-size: 1.125em;
}

h2 {
  font-size: 1em;
}

h3,
.title-small {
  font-size: 0.8125em;

  >div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  br {
    display: none;
  }
}

.title-small span:after {
  /* background: aqua; */
  content: " ";
}

span {
  font-size: 13px;
}

a,
.nav-link {
  color: $light-green;
}

a:hover,
.nav-link:hover {
  text-decoration: none;
  color: $light-green;
}

button {
  font-size: 0.875em;
}

i {
  text-decoration: none;
  font-style: inherit;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}



.title {
  color: white;
  font-size: 1.5em;
}