@import "../../styles/colors";

.thump {
  // background: $dark-green;
  cursor: pointer;
  // margin-bottom: 10px;
  position: relative;
  border-radius: 5px;
  border-width: 8px;
  // height: 100%;
  width: 150px;
  height: 80px;

  .image {
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
  }
}
// .thump:hover {
//   background: $light-green;
// }

.info {
  text-align: left;
}

.action-icon {
  width: 2em;
  margin-bottom: 0.2em;
}

.action-icon > svg {
  fill: $dark-green;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.pagination {
  margin: 0;
}

.back-btn{
  width: 142px;
  flex-grow: inherit;
}